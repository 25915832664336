import * as s from '../../styles/produto.module.scss';
import Badge from '../../../components/badge';
import CTA from '../../../components/cta';
import Features from '../../../components/features';
import Layout from '../../../components/layout';
import Product from '../../../components/product';
import React, { ReactElement } from 'react';
import { Link } from 'gatsby';

export default function MR1(): ReactElement {
  return (
    <Layout title="MRMobile">
      <main>
        <Product
          title="MRMobile"
          description="O Sistema de Pedidos Mobile da SysRS."
          alt="Imagem MRMobile"
          imgName="mrmobile"
          className={`${s.Section} u-nav-clear-padding u-background-gray`}
        >
          <Badge apple />
          <Badge />
        </Product>

        <section className={s.About}>
          <div className={s.About_box}>
            <h1 className="title">Sobre o MRMobile</h1>
            <h2 className="subtitle">Veja os seus recursos e tecnologias</h2>
            <p>
              O MRMobile possibilita a criação de <b>pedidos a partir de um dispositivo móvel</b>, integrando com nosso
              software de gestão empresarial, o <Link to="/produtos/mr1">MR1</Link>.
            </p>
            <p>
              Com o MRMobile, você realiza pedidos de compra diretamente com a fábrica ou fornecedor, mantendo o catálogo de
              produtos <b>sempre disponível com você</b>, até mesmo quando estiver <b>sem internet</b>. O catálogo se adapta
              às necessidades de sua empresa, sendo amplamente configurável para melhor atender seu negócio.
            </p>
          </div>
        </section>

        <Features
          title="Recursos"
          subtitle="Veja algumas coisas que o MRMobile pode fazer"
          name="mre"
          list={[
            'Realize pedidos até mesmo sem internet.',
            'Catálogos personalizáveis por representante.',
            'Cadastro de novos clientes.',
            'Possibilidade de trabalhar com o conceito de grade.',
            'Diversas imagens para um mesmo produto e cor.',
            'Diversas opções de tabelas de preços.',
            'Região de venda configurável por representante.',
            'Controle de cotas por marcas, segmentos e outros.',
            'Pedidos de pronta-entrega quando estiver online.',
          ]}
          primary
        />

        <CTA />
      </main>
    </Layout>
  )
}
