import * as s from './styles/badge.module.scss';
import React, { ReactElement } from 'react';

interface Props {
  apple?: boolean
}

export default function Badge({ apple }: Props): ReactElement {
  return (
    <a
      className={s.Badge_link}
      target="_blank"
      href={apple ?
        'https://apps.apple.com/br/app/mrmobile/id1583029346?itsct=apps_box_badge&amp;itscg=30200' :
        'https://play.google.com/store/apps/details?id=br.com.sysrs.mrmobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'} >
      <img
        className={`${s.Badge_image} ${apple ? s.Badge_image___apple : ''}`}
        alt={apple ? 'Baixar na App Store' : 'Disponível no Google Play'}
        src={apple ?
          'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1633392000&h=479628796e3907fcc9560a02a396f04a' :
          'https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png'}
      />
    </a>
  )
}
